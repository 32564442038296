<template>
  <v-app id="smid">
    <v-main>

      <smid-nav :drawer="drawer"></smid-nav>

      <smid-app-bar :status="status" v-on:toggleDrawer="drawer = !drawer"></smid-app-bar>

      <v-container fluid>
        <smid-alerts></smid-alerts>
        <router-view/>
      </v-container>

    </v-main>

  </v-app>
</template>

<script>
  import { HTTP } from '@/services/axios.js'
  import Navigation from '@/components/Navigation'
  import AppBar from '@/components/AppBar'
  import Alerts from '@/components/Alerts'
  import { bus } from './main'

  export default {
    title: 'SMID',
    components: {
      "smid-nav": Navigation,
      "smid-app-bar": AppBar,
      "smid-alerts": Alerts
    },
    data () {
      return {
        drawer: true,
        socket_url: window.location.hostname == 'localhost' ? 'ws://localhost:8000/ws' : 'wss://' + window.location.hostname + '/api/ws',
        ws: null,
        status: {
          "dashboard": { "name": "Dashboard", "online": true },
          "api": { "name": "API", "online": false },
          "model": { "name": "Incident detectie", "online": false }
        },
        statusDialog: false,
        helpDrawer: false
      }
    },
    watch: {
      incidentDetectionSysCount: function(val) {
        if(val > 0) {
          this.status["model"]["online"] = true
          this.$store.dispatch('deleteAlert', 'incident_detection')
        } else {
          this.status["model"]["online"] = false
          //this.$store.dispatch('addAlert', {
          //  id: "incident_detection",
          //  type: "warning",
          //  message: "Er is momenteel geen systeem verbonden voor het detecteren van incidenten.",
          //  dismissable: true
          //})
        }
      }
    },
    computed: {
      incidentDetectionSysCount() {
        return this.$store.getters.getSystemCount
      }
    },
    methods: {
      requestApiInfo: function() {
        HTTP
          .get('/')
          .then(response => (
            this.$store.dispatch('setApiInfo', response.data)
          ))
      },
      connectSocket: function() {
        this.ws = new WebSocket(this.socket_url)
        
        this.ws.onopen = () => {
          this.status["api"].online = true
          this.requestApiInfo()
          this.$store.dispatch('deleteAlert', 'websocket')
          this.ws.send('{"message": "getOpenIncidents"}')
          console.log("Connected to socket at " + this.socket_url)
        }

        this.ws.onmessage = (event) => {
          this.handleMessage(JSON.parse(event.data))
        }

        this.ws.onclose = () => {
          this.status["api"].online = false
          //this.$store.dispatch('addAlert', {
            //id: "websocket",
            //type: "error",
            //message: "De verbinding met het systeem wat incidenten meldt is verbroken. Er wordt automatisch geprobeerd om opnieuw verbinding te maken.",
            //dismissable: true
          //})
          //console.log('Socket connection lost. Reconnect will be attempted in 1 second.', e.reason)
          //setTimeout(() =>  {
          //  this.connectSocket()
          //}, 1000);
        }

        this.ws.onerror = () => {
          this.status["api"].online = false
          //console.error('Socket encountered error: ', err.message, 'Closing socket');
          this.ws.close();
        }
      },

      handleMessage: function(data) {
        // console.log(data)
        if(data.message_type == 'new_incident'){
          delete data.message_type
          this.$store.dispatch('addIncident', data)
        }else if(data.message_type == 'open_incidents'){
          this.$store.dispatch('setIncidents', data.data)
        }else if(data.message_type == 'new_relation') {
          this.$store.dispatch('addRelation', data)
        }else if(data.message_type == 'nr_detection_systems') {
          this.$store.dispatch('setDetectionSystemCount', data.nr)
        }
      }
    },
    mounted: function() {
      // Create a default alert that there is no incident detection system connected, this will be cleared once the websocket connects and informs the dashboard of the number of connections
      // this.$store.dispatch('addAlert', {
      //   id: "incident_detection",
      //   type: "warning",
      //   message: "Er is momenteel geen systeem verbonden voor het detecteren van incidenten.",
      //   dismissable: true
      // })
      // Connect to the websocket
      this.connectSocket()
      bus.$on('emit', (data) => {
        this.ws.send('{"message": "' + data.message + '", "id": "' + data.id + '"}')
      })
    }
  }
</script>

<style>
#smid {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: break-word;
  text-rendering: optimizeLegibility;
  color: #202020;
}

h1, h2, h3, h4, h5, h6 {
  padding-top: 8px;
  color: #004682;
}
</style>


<template>
  <div :style="{ padding: 50 + 'px', padding: 10 + 'px' }">
    <div id="myEmojis">
      <a v-for="(emoji, index) in emojiList" :key="index" @click="query">{{emoji[0]}}</a>
    </div>
  </div>
</template>


<script>

export default {
  data: function() {
    return {
      windowHeight: window.innerHeight,
      options: {},
      emojiList: [],
    }
  },

  mounted() {
    this.reCalc(this.tweets);
  },

  watch: {
    tweets(newTweets) {
      this.reCalc(newTweets);
    },
  },

  methods: {
    query: function (some_v) {
      this.$store.commit('updateKeywords', [some_v.target.innerText]);
      console.log("Emoji click");
      console.log(this.$store.state.keywords);
    },
    reCalc: function (tweetList) {
      let emojisCounts = {};

      for (let i = 0; i < tweetList.length; i++) {
        let emojis = tweetList[i].emojis || [];
        for (let j = 0; j < emojis.length; j++) {
          let emoji = emojis[j]
          if ( Object.hasOwn(emojisCounts, emoji) ) {
            emojisCounts[emoji] = emojisCounts[emoji] + 1;
          } else {
            emojisCounts[emoji] = 1;
          }
        }
      }

      // Create items array
      var items = Object.keys(emojisCounts).map(function(key) {
        return [key, emojisCounts[key]];
      });

      // Sort the array based on the second element
      items.sort(function(first, second) {
        return second[1] - first[1];
      });

      // Create a new array with only the first 5 items
      this.emojiList = items.slice(0, 10);
    },
  },

  computed: {
    dynamicHeight() {
      const otherElement = this.$refs.appBar;
      const seachBar = this.$refs.searchBar;
      const otherElementHeight = otherElement ? otherElement.clientHeight : 64;
      const seachBarHeight = seachBar ? seachBar.clientHeight : 76;
      const margin = 60;
      return window.innerHeight - otherElementHeight - seachBarHeight - margin;
    },

    tweets() {
      return this.$store.state.tweets;
    },
  },
}


</script>

<style scoped>

html,
body {
}
#myEmojis {
  width: 100%;
  text-align: center;
  font-size: 2em;
}

</style>


<template>
  <div :style="{ height: dynamicHeight + 'px' }">
    <l-map style="height:100%;" ref="map" :center="center" :zoom="zoom" @update:center="centerUpdate" @update:zoom="zoomUpdate">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="attribution"/>
      <LeafletHeatmap ref="heatmap" :key="heatmapKey" :latLng="tweetLocationArray" :min-opacity="10" :blur="21" :radius="15"></LeafletHeatmap>
    </l-map>
  </div>
</template>


<script>
import {latLng } from 'leaflet';
import LeafletHeatmap from '@/assets/dependencies/Vue2LeafletHeatmap'

export default {
  namapHeightme: 'Map',


  components:{
    LeafletHeatmap
  },


  mounted() {
    this.reCalc(this.tweets);
  },


  watch: {
    //tweets(newTweets) {
    //  this.reCalc(newTweets);
      //if (this.$refs.heatmap) this.$refs.heatmap.clearMap();
      //this.tweetLocationArray(newTweets);
    //},
    //tweetLocationArray: function(val) {
    //  if (val.length == 0) this.heatmapKey += 1
    //},
    tweets(newTweets) {
      // Update heatmapData whenever latLongList changes
      if (this.$refs.heatmap) this.$refs.heatmap.clearMap();
      if (this.$refs.heatmap) this.$refs.heatmap.addLatLng(0, 0, 1);

        this.reCalc(newTweets);

      //this.tweetLocationArray = newList.map(tweet => ({
      //  latLng: [tweet.lat, tweet.lng], // LatLong format: [latitude, longitude]
      //  value: 1,        // Adjust the value based on your heatmap requirements
      //}));
      //console.log(this.tweetLocationArray);
    },
  },


  methods: {
    reCalc: function (tweetList) {
      console.log("MAP!!!");
      console.log(this.$refs.heatmap);
      if (this.$refs.heatmap) {
        this.$refs.heatmap.clearMap();
        this.$refs.heatmap.mapObject.on('click', function() { console.log("CLICK MAP!!!") });
      }
      this.tweetLocationArray = [];
      for (let i = 0; i < tweetList.length; i++) {
        let tweet = tweetList[i];
        if (tweet.lat != null && tweet.lng != null) {
          this.tweetLocationArray.push(
            [tweet.lat, tweet.lng]
          );
          if (this.$refs.heatmap) this.$refs.heatmap.addLatLng(latLng(tweet.lat, tweet.lng, 1));
        }
      }
      console.log(this.tweetLocationArray);


      ////if (this.$refs.heatmap) this.$refs.heatmap.clearMap();
      ////let dataLatLong = [];
      //for (let i = 0; i < tweetList.length; i++) {
      //  var x = tweetList[i].lat;
      //  var y = tweetList[i].lng;
      //  //var c = tweetList.latLngCount;

      //  if (x != null && y != null) {
      //    if (this.$refs.heatmap) this.$refs.heatmap.addLatLng(latLng(x, y, 1))
      //  }

      //  //console.log(latLng(x, y, c));
      //  //console.log(this.$refs.heatmap);

      //  //dataLatLong.push({lat: x, lng: y, latLngCount:c});
      //}

      ////this.tweetLocationArray(tweetList);
    },

    centerUpdate(center) {
      this.currentCenter = center;
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
  },


  data: function() {
    return {
      selectedIncident: null,
      binSize: 5,
      center: latLng(52.140231, 4.87726),
      currentCenter: latLng(52.140231, 4.87726),
      zoom: 7,
      currentZoom: 7,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      heatmapKey: 0,
      windowHeight: window.innerHeight,
      //tweetLocationArray: [],
      latLngField: "latLng", // Field containing latLng information in the data array
      valueField: "value",   // Field containing values for heatmap intensity
      tweetLocationArray: [
//        [52.3080392, 4.7621975], 
//        [52.2952203, 6.5772427], 
//        [52.3080392, 4.7621975], 
//        [51.5794077, 5.7135574], 
//        [52.3080392, 4.7621975], 
//        [52.1594747, 4.4908843], 
//        [53.0006, 6.68635], 
//        [52.2132601, 4.5583307], 
//        [53.0006, 6.68635], 
//        [52.2434979,5.6343227], 
      ],       // Array containing heatmap data
    }
  },


  //watch: {
  //  tweetLocationArray: function(val) {
  //    if (val.length == 0) this.heatmapKey += 1
  //  }

  //  tweets(newTweets) {
  //    if (this.$refs.heatmap) this.$refs.heatmap.clearMap();
  //    this.tweetLocationArray(newTweets);
  //  },
  //},

  computed: {
    dynamicHeight() {
      const otherElement = this.$refs.appBar;
      const seachBar = this.$refs.searchBar;
      const otherElementHeight = otherElement ? otherElement.clientHeight : 64;
      const seachBarHeight = seachBar ? seachBar.clientHeight : 76;
      const margin = 60;
      return window.innerHeight - otherElementHeight - seachBarHeight - margin;
    },
    tweets() {
      return this.$store.state.tweets;
    },
  },

    //tweetLocationArray() {
    //  let dataLatLong = [];

    //  for (let i = 0; i < this.tweets.length; i++) {
    //    var x = this.tweets[i].lat;
    //    var y = this.tweets[i].lng;
    //    var c = this.tweets[i].latLngCount;

    //    dataLatLong.push({lat: x, lng: y, latLngCount:c});

    //    if (this.$refs.heatmap) this.$refs.heatmap.addLatLng(latLng(x, y, c))
    //  }
    //  return dataLatLong;
    //},
}


</script>

<style scoped>
  .vue2leaflet-map {
    z-index: 0;
  }
</style>


<template>
  <div ref="searchBar" :style="{ width: '100%' }">
          <v-combobox
            clearable
            chips
            multiple
            v-model="keyword"
            :items="keywordsDisp"
            label="Keywords"
            placeholder="Type or select keyword"
            close-on-content-click="true"
          >

          <!--
            <template v-slot:item="{ item }">
              <div class="custom-item-container" :class="{ 'custom-item-class': true }">
                <div class="default-checkbox">
                </div>
                <div class="item-content">{{ item }}</div>
              </div>
            </template>
          -->
          </v-combobox>
  </div>
</template>


<script>

export default {
  components: {
  },

  data: function() {
    return {
      selectedIncident: null,
      container: null,
      errorMessages: '',
      keyword: null,
      formHasErrors: false,
      keywordsDisp: [],
      //tweetsOrg: JSON.parse(JSON.stringify(this.$store.state.tweets)),
      tweetsOrg: this.$store.state.tweetsOrg,
    }
  },

  mounted() {
    for (let i = 0; i < this.tweets.length; i++) {
      let keyWords = Object.keys(this.tweets[i].keyword_clusters);
      for (let j = 0; j < keyWords.length; j++) {
        let keyWord = keyWords[j];
        this.keywordsDisp.push(keyWord);
      }
    }
    console.log(this.keywordsDisp);
    this.keyword = this.keywords;
  },

  computed: {
    form () {
      return {
        keyword: this.keyword,
      }
    },
    tweets() {
      return this.$store.state.tweets;
    },
    keywords() {
      return this.$store.state.keywords;
    },
  },

  watch: {
    name () {
      this.errorMessages = ''
    },

    keywords (selectedKeywords) {
      this.keyword = selectedKeywords;
    },

    keyword (selectedKeywords, previousKeyword) {
      console.log(previousKeyword);
      console.log(selectedKeywords);
      let newTweets = [];
      // TODO include OR functionality (keep AND)
      for (let i = 0; i < this.tweetsOrg.length; i++) {
        let keyWords = Object.keys(this.tweetsOrg[i].keyword_clusters).concat(this.tweetsOrg[i].topics).concat(this.tweetsOrg[i].emojis);
        let addTweet = true;
        for (let j = 0; j < selectedKeywords.length; j++) {
          if (!keyWords.includes(selectedKeywords[j])) {
            addTweet = false;
            break;
          }
        }
        if (addTweet) {
          newTweets.push(this.tweetsOrg[i]);
        }
      }
      this.updateTweets(newTweets);
      this.updateKeywords(selectedKeywords);
    },

    tweets(newTweets) {
      this.keywordsDisp = [];
      for (let i = 0; i < newTweets.length; i++) {
        let keyWords = Object.keys(newTweets[i].keyword_clusters);
        for (let j = 0; j < keyWords.length; j++) {
          let keyWord = keyWords[j];
          this.keywordsDisp.push(keyWord);
        }
      }
      console.log(this.keywordsDisp);
    },
  },

  methods: {
    updateTweets(newTweetList) {
      this.$store.commit('updateTweets', newTweetList);
    },
    updateKeywords(newKeywordList) {
      this.$store.commit('updateKeywords', newKeywordList);
    },
    //addressCheck () {
    //  this.errorMessages = this.address && !this.name
    //    ? `Hey! I'm required`
    //    : ''

    //  return true
    //},
    //resetForm () {
    //  this.errorMessages = []
    //  this.formHasErrors = false

    //  Object.keys(this.form).forEach(f => {
    //    this.$refs[f].reset()
    //  })
    //},
    //submit () {
    //  this.formHasErrors = false

    //  Object.keys(this.form).forEach(f => {
    //    if (!this.form[f]) this.formHasErrors = true

    //    this.$refs[f].validate(true)
    //  })
    //},
  },
}
</script>

<style scoped>
.custom-item-class {
  background-color: yellow; /* Set the desired background color here */
}
</style>

